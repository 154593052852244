<template>
    <div class="abs-center">
        <div class="my-3">

            <b-container>
                <div class="card card-default animated-fast fadeInDown" v-if="meeting">
                    <div class="card-body">
                        <div class="text-bold text-md mb-3">{{ meeting.name }}</div>

                        <markdown-parser :source="meeting.description"></markdown-parser>

                        <hr/>

                        <div class="text-center" v-if="!meeting.link_replay">
                            <p class="text-md text-bold" v-if="!status.started">
                                {{
                                    $t('appointment.view.meeting.starts_at', {from_now: $moment.utc(meeting.starts_at).local().fromNow(), time: $moment.utc(meeting.starts_at).local().format('HH:mm')})
                                }}
                            </p>
                            <p class="text-md text-bold" v-if="status.started">
                                {{
                                    $t('appointment.view.meeting.started_at', {from_now: $moment.utc(meeting.starts_at).local().fromNow(), time: $moment.utc(meeting.starts_at).local().format('HH:mm')})
                                }}
                            </p>

                            <b-btn variant="primary" v-if="status.can_join" class="btn-xl" :href="this.meeting.link" target="_blank">{{ $t('appointment.view.meeting.join_now') }}</b-btn>

                            <p class="alert alert-primary mt-2" v-if="!status.started">
                                {{ $t('appointment.view.meeting.join_on', {starts_at: $moment.utc(meeting.starts_at).local().subtract('minutes', 15).calendar()}) }}
                            </p>
                        </div>

                        <div v-if="meeting.link_replay">
                            <iframe width="100%" height="500px" :src="'https://www.youtube.com/embed/' + meeting.link_replay + '?autoplay=1'" frameborder="0"

    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        <template v-if="user_id && !meeting.link_replay">

                            <hr/>

                            <div>
                                <p>{{ $t('appointment.view.meeting.user_intro', {user: $store.getters.user('display_name')}) }}</p>

                                <b-input-group @click="copyRegister()">
                                    <b-input onClick="this.select();" ref="refLinkRegister"
                                             v-bind:value="short_url + meeting.referral.code"
                                             readonly></b-input>
                                    <b-input-group-append>
                                        <b-button variant="default"><i class="fad fa-clipboard"></i></b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </div>
                        </template>

                        <template v-if="!user_id">

                            <hr/>

                            <div class="text-center">
                                <p>{{ $t('appointment.view.meeting.register_now_intro') }}</p>

                                <b-btn variant="primary" class="btn-lg my-3" :to="{name: 'Register'}">{{ $t('appointment.view.meeting.register_now') }}</b-btn>
                            </div>
                        </template>

                    </div>
                </div>
            </b-container>


        </div>
    </div>
</template>
<script>
    import MarkdownParser from "../../../../components/Common/MarkdownParser";

    export default {
        components: {MarkdownParser},
        data() {
            return {
                meeting: null,
                user_id: null,
                short_url: '',
                status: {
                    can_join: false,
                    started: false,
                }
            }
        },
        beforeMount() {


            let params = {};

            if (this.$store.getters.user('id')) {
                params.user_id = this.$store.getters.user('id');
                this.user_id = params.user_id;
            }

            this.short_url = process.env.VUE_APP_MEETING_URL;

            this.$api.get('appointment/meeting', params).then(response => {

                if (!response.data) {
                    this.$router.push({name: 'Dashboard'});
                    return true;
                }

                this.meeting = response.data;

                if (!this.$route.params.vanity_url) {
                    this.$router.push({name: 'Appointment:Meeting', params: {vanity_url: this.meeting.vanity_url}})
                }

                if (this.$i18n.locale === 'de') {
                    this.meeting.name = this.meeting.name_de;
                    this.meeting.description = this.meeting.description_de;
                }

                if (this.$i18n.locale === 'en') {
                    this.meeting.name = this.meeting.name_en;
                    this.meeting.description = this.meeting.description_en;
                }

                this.checkStatus();

                this.checkStatusInterval = setInterval(this.checkStatus, 1000);
            });

        },
        methods: {
            checkStatus() {
                let duration = this.$moment.duration(this.$moment.utc(this.meeting.starts_at).diff(this.$moment(new Date())));
                let minutes = parseInt(duration.asMinutes());

                if (minutes < 16) {
                    this.status.can_join = true;
                }

                if (minutes < 0) {
                    this.status.started = true;
                }

                this.$forceUpdate();
            },
            copyRegister() {
                this.$copyText(this.short_url + this.meeting.referral.code);
                this.copy();
            },
            copy() {
                this.$notify({
                    group: 'main',
                    title: 'Ref-Link copied!',
                    type: 'success',
                    data: {
                        class: 'far fa-clipboard',
                    }
                });
            },
        }
    }
</script>